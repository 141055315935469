import { ReactComponent as SVG_POSTTEST } from '../../../../assets/newAssets/Property 1=rpostTest.svg';
import { FileUploader } from 'react-drag-drop-files';
import { QueryInfo } from '../Brief/Brief';
import { IconContainer } from './ReportsLinks';
import { useCampaignContext } from 'context/CampaignContext';
import { useContext } from 'react';
import { saveFiletoS3 } from '../Brief/UploadFile/saveFiletoS3';
import { fileTypes } from 'components/Main/Gallery/ARContainer';
import APIContext from 'context/APIContext';
import ClientStatementsContext from 'context/ClientStatementsContext';

type Props = {
  document: QueryInfo[];
  setDocument: React.Dispatch<React.SetStateAction<QueryInfo[]>>;
  uploadedFiles: QueryInfo[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<QueryInfo[]>>;
  isClient: boolean;
  openSelectedDocumentInNewTab: () => void;
  fullAccess: boolean | null | undefined;
  readAccess: boolean | null | undefined;
  noAccess: boolean;
  isUserAuthorized: boolean | undefined;
  postDocument: (file: File | undefined, previewFile?: any) => void;
  onFileOpen: (file: QueryInfo) => void;
};

const DocumentUploader = (props: Props) => {
  const {
    setDocument,
    document,
    uploadedFiles,
    setUploadedFiles,
    isClient,
    openSelectedDocumentInNewTab,
    fullAccess,
    noAccess,
    readAccess,
    postDocument,
    onFileOpen
  } = props;
  const { activeCampaign } = useCampaignContext();
  const { getEnvironment } = useContext(APIContext);
  const { statements } = useContext(ClientStatementsContext);

  //   const Create = statements?.Queries?.CREATE as boolean;
  //   const Read = statements?.Queries?.READ as boolean;

  const selectedFiles = uploadedFiles;

  const addFile = async (files: File) => {
    // console.log('This is the item I want to save!', files);
    if (files) {
      // console.log('This is the item I want to save!', files);

      if (activeCampaign) {
        // console.log('This is the item I want to save!', files);

        const position = 'Summary';

        const saved = saveFiletoS3(
          files,
          selectedFiles.length + 1,
          activeCampaign.campaignId,
          activeCampaign.ClientCode,
          activeCampaign.ClientCountry,
          getEnvironment,
          position,
        );

        // console.log('This is the item I want to save!', saved);

        // uploadNewFile(saved.id, saved.position, files);
      }
    }
  };

  const isUserAuthoriezed = activeCampaign?.authorized as boolean;

  // console.log("hfhdgsgafs", fullAccess, readAccess, isUserAuthoriezed)

  return (
    <>
      {(isUserAuthoriezed || (!isUserAuthoriezed && selectedFiles.length > 0)) && <IconContainer
        style={{ position: 'absolute', right: '100px' }}
        onClick={() => (isUserAuthoriezed ? openSelectedDocumentInNewTab() : onFileOpen(selectedFiles[0]))}
        active={true.toString()}
        className={isUserAuthoriezed ? '' : !isUserAuthoriezed && selectedFiles.length > 0 ? '' : 'IsDisabled'}
      // theme={themee}
      >
        {isUserAuthoriezed ? (
          <FileUploader
            className="drop_area"
            handleChange={(file: any) => {
              postDocument(file);
              addFile(file);
            }}
            name="file"
            types={fileTypes}
            children={
              <IconTest
                document={document}
                isClient={isClient}
                fullAccess={fullAccess}
                readAccess={readAccess}
                noAccess={noAccess}
              />
            }
          />
        ) : (
          <IconTest
            document={document}
            isClient={isClient}
            fullAccess={fullAccess}
            readAccess={readAccess}
            noAccess={noAccess}
          />
        )}
      </IconContainer>}
    </>
  );
};

export default DocumentUploader;

export const IconTest = ({
  document,
  isClient,
  fullAccess,
  readAccess,
  noAccess,
}: {
  document: QueryInfo[];
  isClient: boolean;
  fullAccess: boolean | null | undefined;
  readAccess: boolean | null | undefined;
  noAccess: boolean;
}) => (
  <>
    {/* {fullAccess ? (
      <>
        {readAccess ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
            <SVG_POSTTEST className="svg" />
            <h2>View File</h2>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
            <SVG_POSTTEST className="svg" style={{ fill: document.length > 0 ? '#E8EAEF' : '' }} />
            <h2 className={document.length > 0 ? 'green' : ''}>Post File</h2>
          </div>
        )}
      </>
    ) : (
      <></>
    )} */}

    {fullAccess ? (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
        <SVG_POSTTEST className="svg" /* style={{ fill: document.length > 0 ? '#E8EAEF' : '' }} */ />
        <h2 /* className={document.length > 0 ? 'green' : ''} */>Campaign Evaluation</h2>
      </div>
    ) : (

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
        <SVG_POSTTEST className="svg" />
        <h2>View Test</h2>
      </div>
    )}
  </>
);
