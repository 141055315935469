import {
  ActionEnum,
  DashboardTypes,
  GetFlowchartCampaignsQuery,
  GetPublishedCampaigns_v2Query,
  StatementType,
  UserIsAuthorizedMultiQuery,
  UserIsAuthorizedQuery,
  agencyEnum,
  client,
  country,
  countryEnum,
  datatype,
  filtertype,
  flowchartCampaignWithType,
  localQuery,
  metricFunction,
  publishedCampaignsResponse_v2,
  publishedType,
  queryInput,
  subCampaignResponse,
} from 'API';
import { API } from 'aws-amplify';
import { useClientContext } from 'context/ClientContext';
import { useDataQuery } from 'customHooks/useDataQuery';
import { useCampaignId, useClientCode, useSubCampaignId } from 'customHooks/useParameters';
import { useActiveSubCampaign } from 'customHooks/useSubcampaignList';
import { over, set } from 'lodash';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { readRemoteFile } from 'react-papaparse';
import {
  getFlowchartCampaigns,
  getPublishedCampaigns_v2,
  userIsAuthorized,
  userIsAuthorizedMulti,
} from '../graphql/queries';
import APIContext from './APIContext';
import AuthContext from './AuthContext';
import { queryFunction } from './queryFunction';
import { usePrepKPIQuery } from 'customHooks/prepKPIquery';
import { useGetStoredQueriesDetails } from 'customHooks/useGetStoredQueriesDetails';
import { useGetSummaryStatus } from 'customHooks/useGettSummaryStatus';

type SubCampaignObject = {
  [key: number]: {
    campaignId: string;
    subCampaignId: string;
    published: publishedType;
    __typename: string;
  };
};

export type ACdetails =
  | (flowchartCampaignWithType & { subcampaign: SubCampaignObject } & { authorized: boolean })
  | undefined;
interface IFilter {
  code: string;
  country: string;
}
interface mediaSourceData {
  media_source: string;
  actual_cost: number;
}
interface yearWeekData {
  year_week: string;
  actual_cost: number;
}

type CampaignContextProps = {
  campaignList: Array<ACdetails> | undefined;
  // setCampaignList: (args: Array<ACdetails>) => void;
  activeCampaign: ACdetails;
  // setActiveCampaign: (args: ACdetails) => void;
  // dashType: string,
  setDashType: (arg: DashboardTypes) => void;
  dashType: DashboardTypes | null;
  TVStationSelector: string;
  setTVStationSelector: (args: string) => void;
  RadioStationSelector: string;
  setRadioStationSelector: (args: string) => void;
  clientFilterList: IFilter[];
  setClientFilterList: (args: IFilter[]) => void;
  reducedClientList: Array<ACdetails> | undefined;
  filterCampaignsPerClientCode: (args: IFilter[]) => void;
  actualBudget: number;
  numberOfCampaigns: number;
  campaignActualBudget: number;
  costPerMedia: Array<mediaSourceData> | undefined;
  costPerWeek: Array<yearWeekData> | undefined;
  currentYearBudget: number | undefined;
  recommendedYearBudget: number | undefined;
  approvedBudget: number | undefined;
  startDateFilter: string;
  setStartDateFilter: (args: string) => void;
  endDateFilter: string;
  setEndDateFilter: (args: string) => void;
  order: string;
  setOrder: (args: string) => void;
  fetchedPortalData: Array<IData>;
  permanentCampaignList: Array<ACdetails> | null;
  setPermanentCampaignList: (args: Array<ACdetails>) => void;
  navigationCampaignList: Array<ACdetails> | undefined;
  availableCampaigns: Array<ACdetails> | undefined;
  checkDashPublished: (
    subCampaignO:
      | {
          campaignId: string;
          subCampaignId: string;
          published: publishedType;
          __typename: string;
        }
      | undefined,
    dash: DashboardTypes,
  ) => boolean;
  activeSubCampaign: subCampaignResponse | undefined;
  actualBudgetPublished: number | undefined;
  subCampaignList: subCampaignResponse[];
  updatePermanentCampaignList: (args: string, arg2: string) => void;
  /*   handlePublishSummary: () => void;
    campaignOverFlowqueryStatus: string; */

  overflowKPIquery: queryInput | undefined;
  setClientSearchTerm: (args: string) => void;
  excludeAlwaysOn: boolean;
  setExcludeAlwaysOn: (args: boolean) => void;
  canDoInsourceAndOnInsourcePortal: boolean;
};
export enum status {
  UNBRIEFED = 'UNBRIEFED',
  BRIEFED = 'BRIEFED',
  UNPUBLISHED = 'UNPUBLISHED',
  PARTPUBLISHED = 'PARTPUBLISHED',
  PUBLISHED = 'PUBLISHED',
}

const { REACT_APP_AGENCY } = process.env;
export const CampaignContext = createContext<CampaignContextProps | undefined>(undefined);
interface IData {
  actual_cost: number;
  campaign_id: number;
  client_code: string;
  country: string;
  media_source: string;
  year_week: string;
  end_Date: string;
  start_Date: string;
}

export const CampaignContextProvider = (props: any) => {
  const { user, isAuthorized } = useContext(AuthContext);
  const [orgId] = useClientCode();
  const [campaignId, , deleteCampaignID] = useCampaignId();
  const [subCampaignId] = useSubCampaignId();
  const [clientSearchTerm, setClientSearchTerm] = useState<string>('');
  const { userCanDoInsource } = useClientContext();
  // const [campaignList, setCampaignList] = useState<Array<ACdetails>>();
  // const [activeCampaign, setActiveCampaign] = useState<ACdetails>();
  const [permanentCampaignList, setPermanentCampaignList] = useState<Array<ACdetails> | null>(null);
  const { getEnvironment } = useContext(APIContext);

  const [dashType, setDashType] = useState(null as DashboardTypes | null);
  const [excludeAlwaysOn, setExcludeAlwaysOn] = useState<boolean>(false);
  const [TVStationSelector, setTVStationSelector] = useState('All stations');
  const [RadioStationSelector, setRadioStationSelector] = useState('All stations');
  const [clientFilterList, setClientFilterList] = useState<IFilter[]>([]);
  const [reducedClientList, setReducedClientList] = useState<Array<ACdetails>>();
  const [fetchedPortalData, setFetchedPortalData] = useState<Array<IData>>([]);

  const [order, setOrder] = useState<string>('desc');
  useEffect(() => {
    dashType === DashboardTypes.FLOWCHART ? setOrder('asc') : setOrder('desc');
  }, [dashType]);

  //create date value in format YYYY-MM-DD for 1st of January this year
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentYearString = currentYear.toString();

  const [startDateFilter, setStartDateFilter] = useState<string>(`${currentYearString}-01-01`);
  // const [startDateFilter, setStartDateFilter] = useState<string>(`2023-01-01`);
  const [endDateFilter, setEndDateFilter] = useState<string>(`${currentYearString}-12-31`);
  const [activeSubCampaign, subCampaignList] = useActiveSubCampaign(dashType);
  const [overflowKPIquery, setOverflowKPIquery] = useState<queryInput>();

  const [overFlowdata, overFlowqueryStatus, overFlowerror, overFlowqueryId, overFlowoutputLocation] = useDataQuery({
    query: overflowKPIquery,
    querySource: 'OVERFLOW KPI' /* , publishedOutputLocation: publishedOutputLocation  */,
  });

  // console.log("overFlowdata", overflowKPIquery)

  useEffect(() => {
    if (overFlowdata) {
      setFetchedPortalData(overFlowdata as Array<IData>);
    } else {
      setFetchedPortalData([]);
    }
  }, [overFlowdata]);

  useEffect(() => {
    if (overFlowerror) {
      setFetchedPortalData([
        {
          actual_cost: -1,
          campaign_id: -1,
          client_code: 'error',
          country: 'error',
          media_source: 'error',
          year_week: 'error',
          start_Date: 'error',
          end_Date: 'error',
        },
      ]);
    }
  }, [overFlowerror]);

  useEffect(() => {
    async function fetchCampaignList(orgId: string) {
      // setPermanentCampaignList([]);
      setPermanentCampaignList(null);
      const date15MonthsAgo = new Date();
      date15MonthsAgo.setMonth(date15MonthsAgo.getMonth() - 18);
      const startDate15MonthsAgo =
        date15MonthsAgo.getFullYear() + '-' + ('0' + (date15MonthsAgo.getMonth() + 1)).slice(-2) + '-' + '15';

      let responseList;
      try {
        const responseListTemp = (await API.graphql({
          query: getFlowchartCampaigns,
          variables: {
            Organization_id: orgId,
            from: startDate15MonthsAgo,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: GetFlowchartCampaignsQuery; errors: any };
        responseList = responseListTemp;
      } catch (error) {
        console.log('error', error);
        alert('Error fetching campaigns. Please contact support at dk.support@annalect.com');
        return [];
      }
      const fetchIsInsource = async (): Promise<boolean> => {
        try {
          const response = (await API.graphql({
            query: userIsAuthorizedMulti,
            variables: {
              Organization_id: orgId,
              Environment: getEnvironment,
              Resource: 'INSOURCE', // Write publish instead of queries,
              Service: 'OMDPortal',
              StatementType: StatementType.Access,
              Action: ActionEnum.WRITE,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })) as { data: UserIsAuthorizedMultiQuery };

          const data = response.data.userIsAuthorizedMulti;
          if (data) {
            const isIt = data.some((e) => e?.Authorized === true);
            return isIt;
          } else {
            return false;
          }
        } catch (e) {
          console.log('ERROR IN FETCH USER IS AUTHORIZED MULTI2', e);
          return false; // Ensure a boolean is always returned
        }
      };

      const response = responseList.data.getFlowchartCampaigns;
      const isInsource = await fetchIsInsource();
      const tempBVOD = response?.map((element) => {
        let updatedDashboardTypes = element?.dashboardType.filter(
          (e) => (e as string) !== 'BVOD' && (e as string) !== 'empty',
        );

        if (element?.dashboardType.includes(DashboardTypes.DIGITAL) && isInsource) {
          updatedDashboardTypes = updatedDashboardTypes?.concat(DashboardTypes.INSOURCE_DATA);
        }

        return {
          ...element,
          dashboardType: updatedDashboardTypes,
        };
      });
      return tempBVOD as Array<flowchartCampaignWithType>;
    }

    async function fetchPublishedCampaignsFromCampaignList(campaignCodesList: string[]) {
      try {
        const publishedlist = (await API.graphql({
          query: getPublishedCampaigns_v2,
          variables: {
            campaigns: campaignCodesList,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: GetPublishedCampaigns_v2Query };
        const resultList = publishedlist.data.getPublishedCampaigns_v2;

        return resultList as Array<publishedCampaignsResponse_v2>;
      } catch (e) {
        console.log(`errorPublishedCampaigns ${e}`, e);
      }
    }

    async function fetchIsUserAuthorizedToSeeUnpublishedCampaigns(
      uniqArray: { ClientCode: string; ClientCountry: string }[],
    ) {
      try {
        const response = (await API.graphql({
          query: userIsAuthorized,
          variables: {
            Clients: uniqArray,
            Resource: 'UnPublishedCampaigns',
            Environment: getEnvironment,
            Action: ActionEnum.READ,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: UserIsAuthorizedQuery };

        const pero = response.data.userIsAuthorized;
        // Create
        return pero as Array<{ Client: { ClientCode: string; ClientCountry: string }; Authorized: boolean }>;
      } catch (e) {
        console.log('ERROR IS IN USER_IS_AUTHORIZED', e);
      }
    }
    if (orgId !== 'none' && isAuthorized) {
      fetchCampaignList(orgId)
        .then((res) => {
          const campaignList = res;

          if (campaignList !== null && campaignList !== undefined) {
            // const campaignCodesList = campaignsDetails.map(element => element.campaignId?.toString())
            const campaignCodesList = campaignList.map((element) => element.campaignId?.toString());
            // console.log("this is the fetchedCampaignList stuff", campaignCodesList)
            fetchPublishedCampaignsFromCampaignList(campaignCodesList).then((res) => {
              const resultList = res;
              // console.log("123 resultList", resultList)
              const updatedCampaignDetails4 = campaignList.map((element) => ({
                ...element,
                subcampaign: resultList
                  ?.filter((el) => el?.campaignId === element.campaignId?.toString())
                  .reduce((acc: any, curr: any) => {
                    acc[curr['subCampaignId'] as keyof typeof acc] = {
                      ...curr,
                    };
                    return acc;
                  }, {}),
              }));

              const onlyCodeandCountry = updatedCampaignDetails4.map((e) => {
                return {
                  ClientCode: e.ClientCode,
                  ClientCountry: e.ClientCountry,
                };
              });

              const uniqArray = [
                ...new Map(
                  onlyCodeandCountry.map((v) => [JSON.stringify([v.ClientCode, v.ClientCountry]), v]),
                ).values(),
              ];

              fetchIsUserAuthorizedToSeeUnpublishedCampaigns(uniqArray).then((res) => {
                const finalResult = updatedCampaignDetails4.map((el) => {
                  const foundObject = res?.find(
                    (peroEl) =>
                      JSON.stringify(peroEl?.Client) ===
                      JSON.stringify({
                        ClientCode: el.ClientCode,
                        ClientCountry: el.ClientCountry,
                        __typename: 'Clients',
                      }),
                  );
                  return { ...el, authorized: foundObject?.Authorized as boolean };
                });
                console.log('This is the permanentCampaignList ', finalResult);

                setPermanentCampaignList(finalResult);
              });
            });
          } else {
            // setPermanentCampaignList([]);
            setPermanentCampaignList(null);
          }
        })
        .catch((err) => {
          // setPermanentCampaignList([]);
          setPermanentCampaignList(null);
        });
    } else {
      // setPermanentCampaignList([]);
      setPermanentCampaignList(null);
    }
  }, [getEnvironment, orgId, isAuthorized]);

  const isHomeDashboard =
    dashType === DashboardTypes.HOME ||
    dashType === DashboardTypes.FLOWCHART; /* || dashType === DashboardTypes.INSOURCE_DATA */
  const canDoInsourceAndOnInsourcePortal = userCanDoInsource && dashType === DashboardTypes.INSOURCE_DATA;

  const availableCampaigns =
    permanentCampaignList !== null && isHomeDashboard
      ? permanentCampaignList?.flatMap((el) => {
          if (el === undefined) {
            return [];
          } else {
            return el;
          }
        })
      : permanentCampaignList?.flatMap((el) => {
          if (el === undefined) {
            return [];
          }
          //check if el is dashboard type array
          if (el.dashboardType.some((dt) => dt === dashType)) {
            //if yes, check if authorized is true
            if (el.authorized === true || canDoInsourceAndOnInsourcePortal) {
              return el;
            }

            if (
              dashType === 'DIGITAL' ||
              dashType === 'PRINT' ||
              dashType === 'OOH' ||
              dashType === 'CINEMA' ||
              dashType === 'INSOURCE_DATA'
            ) {
              //  check if the subcampaign[-1].published[dashType] is true and return element
              const subcampaigns = el.subcampaign[-1];
              if (subcampaigns === undefined) {
                return [];
              }
              if (subcampaigns.published[dashType === 'INSOURCE_DATA' ? 'DIGITAL' : dashType] === true) {
                return el;
              }
              return [];
            }

            if (dashType === 'TV' || dashType === 'RADIO') {
              //  check if the subcampaign[-1].published[dashType] is true and return element
              // const subcampaigns = el.subcampaign[Number(subCampaignId)];
              for (const [key, value] of Object.entries(el.subcampaign)) {
                if (value.published[dashType] === true) {
                  return el;
                }
              }
              /*      if (subcampaigns === undefined) {
               return [];
             }
             if (subcampaigns.published[dashType] === true) {
               return el;
             } */
              return [];
            }
            return [];
          }
          return [];
        });

  const campaignList =
    dashType === null
      ? undefined
      : availableCampaigns === undefined
      ? undefined
      : dashType === DashboardTypes.HOME
      ? availableCampaigns
      : availableCampaigns
          ?.filter((element) =>
            clientFilterList.length > 0
              ? clientFilterList.some(
                  (element2) => element2?.code === element?.ClientCode && element2?.country === element?.ClientCountry,
                )
              : !clientFilterList.some(
                  (element2) => element2?.code === element?.ClientCode && element2?.country === element?.ClientCountry,
                ),
          )
          .filter((el) => {
            const startDate = new Date(el?.startDate as string);
            const endDate = new Date(el?.startDate as string);
            const startDateFilterDate = new Date(startDateFilter);
            const endDateFilterDate = new Date(endDateFilter);
            const dashboardType = el?.dashboardType as Array<DashboardTypes>;
            return startDate >= startDateFilterDate && endDate <= endDateFilterDate;
          })
          .filter((el) => {
            // console.log("this is the fetchedCampaignList stuff", el)
            if (excludeAlwaysOn) {
              // return campaigns whose difference between start and end date is less than 180 days
              const startDate = new Date(el?.startDate as string);
              const endDate = new Date(el?.endDate as string);
              const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              return diffDays < 180;
            } else {
              return el;
            }
          })
          .sort((a, b) => {
            if (a?.startDate && b?.startDate) {
              if (order === 'asc') {
                return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
              } else {
                return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
              }
            } else {
              return 0;
            }
          })
          .filter((el) =>
            dashType === DashboardTypes.FLOWCHART
              ? el.campaign.toLowerCase().includes(clientSearchTerm.toLowerCase()) ||
                el.campaignId.toString().startsWith(clientSearchTerm)
              : el.campaign.toLowerCase().includes(''),
          );

  const navigationCampaignList =
    permanentCampaignList !== null
      ? permanentCampaignList
          ?.filter((element) =>
            clientFilterList.length > 0
              ? clientFilterList.some(
                  (element2) => element2?.code === element?.ClientCode && element2?.country === element?.ClientCountry,
                )
              : !clientFilterList.some(
                  (element2) => element2?.code === element?.ClientCode && element2?.country === element?.ClientCountry,
                ),
          )
          .filter((el) => {
            const startDate = new Date(el?.startDate as string);
            const endDate = new Date(el?.startDate as string);
            const startDateFilterDate = new Date(startDateFilter);
            const endDateFilterDate = new Date(endDateFilter);
            const dashboardType = el?.dashboardType as Array<DashboardTypes>;

            // console.log("This is the campaign list", dashboardType)

            return startDate >= startDateFilterDate && endDate <= endDateFilterDate;
          })
          .filter((el) => {
            if (excludeAlwaysOn) {
              // return campaigns whose difference between start and end date is less than 180 days
              const startDate = new Date(el?.startDate as string);
              const endDate = new Date(el?.endDate as string);
              const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              return diffDays < 180;
            } else {
              return el;
            }
          })
          .sort((a, b) => {
            if (a?.startDate && b?.startDate) {
              if (order === 'asc') {
                return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
              } else {
                return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
              }
            } else {
              return 0;
            }
          })
      : [];

  const activeCampaignNoUndefined =
    campaignId === 'none'
      ? 'NOT_SELECTED'
      : availableCampaigns?.some((el) => el?.campaignId === Number(campaignId))
      ? availableCampaigns?.find((el) => el?.campaignId === Number(campaignId))
      : undefined;

  const activeCampaign = activeCampaignNoUndefined === 'NOT_SELECTED' ? undefined : activeCampaignNoUndefined;
  useEffect(() => {
    console.log('ActiveCampaign', activeCampaign);
  }, [activeCampaign]);

  const checkDashPublished = (
    subCampaignO:
      | {
          campaignId: string;
          subCampaignId: string;
          published: publishedType;
          __typename: string;
        }
      | undefined,
    dash: DashboardTypes,
  ) => {
    // if (subCampaignO === undefined) return false;

    if (dash === 'DIGITAL') {
      return subCampaignO?.published.DIGITAL === true;
    }
    if (dash === 'TV') {
      return subCampaignO?.published.TV === true;
    }
    if (dash === 'RADIO') {
      return subCampaignO?.published.RADIO === true;
    }
    if (dash === 'PRINT') {
      return subCampaignO?.published.PRINT === true;
    }
    if (dash === 'OOH') {
      return subCampaignO?.published.OOH === true;
    }
    if (dash === 'CINEMA') {
      return subCampaignO?.published.CINEMA === true;
    }
    return false;
  };

  const uniqueFilteredPermanentCampaignList = useCallback(() => {
    const filteredPermanentCampaignList = permanentCampaignList
      ? permanentCampaignList.flatMap((el) => {
          if (el) {
            if (el.ClientCode !== undefined && el.ClientCountry !== undefined && el.ClientCountry in countryEnum) {
              return { country: el.ClientCountry, client: el.ClientCode } as client;
            }
          }
          return [];
        })
      : [];

    return filteredPermanentCampaignList.reduce((acc, current) => {
      const x = acc.find((item) => item.country === current.country && item.client === current.client);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, [] as client[]);
  }, [permanentCampaignList]);

  useEffect(() => {
    if (permanentCampaignList && permanentCampaignList?.length > 0) {
      const reducedList = [
        ...new Map(
          permanentCampaignList?.map((v) => [JSON.stringify([v?.ClientCode, v?.ClientCountry, v?.ClientName]), v]),
        ).values(),
      ];

      setReducedClientList(reducedList);
    }
  }, [permanentCampaignList]);
  // useEffect(() => {
  //   console.log("CAMPAIGN LIST", campaignList)
  // }, [campaignList])

  const [kk, setKK] = useState<client[]>([]);
  useEffect(() => {
    const listofUniques = uniqueFilteredPermanentCampaignList();
    setKK(listofUniques);
  }, [permanentCampaignList, uniqueFilteredPermanentCampaignList]);

  const [a] = usePrepKPIQuery({
    clientCodes: kk,
  });

  useEffect(() => {
    if (a) {
      setOverflowKPIquery(a);
    }
  }, [a]);

  // THIS IS FOR FILTERING DATA FROM THE QUERY
  const filteredPortalData = fetchedPortalData
    .filter((element) =>
      clientFilterList.length > 0
        ? clientFilterList.some(
            (element2) => element2?.code === element?.client_code && element2?.country === element?.country,
          )
        : !clientFilterList.some(
            (element2) => element2?.code === element?.client_code && element2?.country === element?.country,
          ),
    )
    .filter((el) => {
      const startDate = new Date(el?.start_Date as string);
      const endDate = new Date(el?.end_Date as string);
      const startDateFilterDate = new Date(startDateFilter);
      const endDateFilterDate = new Date(endDateFilter);
      // TODO HERE THE END AND START DATE ARE MAKING THE CAMPAIGNS NOT WORKING AS EXPECTED
      return startDate >= startDateFilterDate && endDate <= endDateFilterDate;
    })
    .filter((el) => {
      if (excludeAlwaysOn) {
        // return campaigns whose difference between start and end date is less than 180 days
        const startDate = new Date(el?.start_Date as string);
        const endDate = new Date(el?.end_Date as string);
        const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays < 180;
      } else {
        return el;
      }
    });

  // **************** PREP DATA FOR PORTAL KPI START *************/
  const numberOfCampaigns = campaignList?.length as number;
  // Create a map of campaign_id to actual_cost sum
  const actualCostMap = fetchedPortalData.reduce((acc, fetEl) => {
    const startDate = new Date(fetEl?.start_Date as string);
    const endDate = new Date(fetEl?.end_Date as string);
    const startDateFilterDate = new Date(startDateFilter);
    const endDateFilterDate = new Date(endDateFilter);

    if (startDate >= startDateFilterDate && endDate <= endDateFilterDate) {
      acc[fetEl.campaign_id] = (acc[fetEl.campaign_id] || 0) + (fetEl['actual_cost'] || 0);
    }

    return acc;
  }, {} as Record<string, number>);
  // Map over campaignList and get the actual_cost sum for each campaign from the map
  const campaignsThatRanInSelectedPeriod = campaignList?.map((el) => actualCostMap[el?.campaignId] || 0);
  // Sum all the items in the array campaignsThatRanInSelectedPeriod
  const actualBudget = campaignsThatRanInSelectedPeriod?.reduce((a, b) => a + b, 0) as number;
  // Filter campaignList for campaigns with status 'PUBLISHED', then map over it and get the actual_cost sum for each campaign from the map
  const campaignsThatRanInSelectedPeriodPublished = campaignList
    ?.filter((el) => el.status === 'CAMPAIGN_PUBLISHED')
    .map((el) => actualCostMap[el?.campaignId] || 0);
  // Sum all the items in the array campaignsThatRanInSelectedPeriod
  const actualBudgetPublished = campaignsThatRanInSelectedPeriodPublished?.reduce((a, b) => a + b, 0) as number;
  const currentYearBudget = campaignList?.reduce((a, b) => a + Number(b?.briefed_amount), 0);
  const recommendedYearBudget = campaignList?.reduce((a, b) => a + Number(b?.recommended_budget_ctc), 0);
  const approvedBudget = campaignList?.reduce((a, b) => a + Number(b?.approved_budget), 0);
  // **************** PREP DATA FOR PORTAL KPI END *************/

  const [, summaryPublishedOutputLocation] = useGetSummaryStatus(
    !activeCampaign?.authorized ? activeCampaign : undefined,
  );
  const outputLocation =
    summaryPublishedOutputLocation && summaryPublishedOutputLocation.length > 0
      ? summaryPublishedOutputLocation[0].outputlocation
      : undefined;
  const [campaignOverFlowdata, , , , ,] = useDataQuery({
    query: undefined,
    querySource: 'CAMPAIGN OVERFLOW KPI',
    publishedOutputLocation: outputLocation,
  });
  // console.log("campaignOverFlowdata", campaignOverFlowdata)
  const publishedSummaryData = (campaignOverFlowdata as Array<IData>) ?? [];

  // **************** PREP DATA FOR SUMMARY TAB START *************/
  const filteredPerActiveCampaign = activeCampaign?.authorized
    ? filteredPortalData.filter((el) => el.campaign_id === activeCampaign?.campaignId)
    : publishedSummaryData;
  const campaignActualBudget = filteredPerActiveCampaign.reduce((a, b) => a + (b['actual_cost'] || 0), 0);
  const media_sourceArray1 = filteredPerActiveCampaign.map(({ campaign_id, country, year_week, ...rest }) => rest);
  const filteredPerActiveCampaign3: Record<string, mediaSourceData[]> = media_sourceArray1.reduce((acc, curr) => {
    const key = curr.media_source;
    const curGroup = acc[key as keyof typeof acc] ?? [];
    return { ...acc, [key]: [...curGroup, curr] };
  }, {});
  const costPerMedia: mediaSourceData[] = Object.keys(filteredPerActiveCampaign3).map((key) => {
    return {
      media_source: key,
      actual_cost: filteredPerActiveCampaign3[key].reduce((acc, curr) => acc + curr.actual_cost, 0),
    };
  });
  const year_weekeArray1 = filteredPerActiveCampaign.map(({ campaign_id, country, media_source, ...rest }) => rest);
  const filteredYearWeekArray1: Record<string, yearWeekData[]> = year_weekeArray1.reduce((acc, curr) => {
    const key = curr.year_week;
    const curGroup = acc[key as keyof typeof acc] ?? [];
    return { ...acc, [key]: [...curGroup, curr] };
  }, {});
  const costPerWeek: yearWeekData[] = Object.keys(filteredYearWeekArray1).map((key) => {
    return {
      year_week: key,
      actual_cost: filteredYearWeekArray1[key].reduce((acc, curr) => acc + curr.actual_cost, 0),
    };
  });

  // **************** PREP DATA FOR SUMMARY TAB START *************/

  const filterCampaignsPerClientCode = (filterList: IFilter[]) => {
    setClientFilterList(filterList);
    /*   setCampaignList([]) */
    const filteredCampaignList = permanentCampaignList?.filter((element) =>
      filterList.length > 0
        ? filterList.some(
            (element2) => element2?.code === element?.ClientCode && element2?.country === element?.ClientCountry,
          )
        : !filterList.some(
            (element2) => element2?.code === element?.ClientCode && element2?.country === element?.ClientCountry,
          ),
    );
    setTimeout(() => {
      /*    setCampaignList(filteredCampaignList) */
    }, 500);
  };

  const updatePermanentCampaignList = (amount: string, campaignID: string) => {
    if (permanentCampaignList !== null) {
      const updatedCampaignList = permanentCampaignList?.map((el) => {
        if (el?.campaignId === Number(campaignID)) {
          return {
            ...el,
            budget: amount,
          };
        }
        return el;
      });

      setPermanentCampaignList(updatedCampaignList);
    }
  };

  // const handleUpdateCampaignList = () => {

  // }

  return (
    <CampaignContext.Provider
      value={{
        campaignList,
        activeCampaign,
        // setActiveCampaign,
        setDashType,
        dashType,
        TVStationSelector,
        setTVStationSelector,
        RadioStationSelector,
        setRadioStationSelector,
        clientFilterList,
        setClientFilterList,
        reducedClientList,
        filterCampaignsPerClientCode,
        actualBudget,
        numberOfCampaigns,
        campaignActualBudget,
        costPerMedia,
        costPerWeek,
        currentYearBudget,
        recommendedYearBudget,
        approvedBudget,
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter,
        order,
        setOrder,
        fetchedPortalData,
        permanentCampaignList,
        setPermanentCampaignList,
        navigationCampaignList,
        availableCampaigns,
        checkDashPublished,
        activeSubCampaign,
        subCampaignList,
        updatePermanentCampaignList,
        actualBudgetPublished,
        /*         handlePublishSummary,
                campaignOverFlowqueryStatus, */
        overflowKPIquery,
        setClientSearchTerm,
        excludeAlwaysOn,
        setExcludeAlwaysOn,
        canDoInsourceAndOnInsourcePortal,
      }}
    >
      {props.children}
    </CampaignContext.Provider>
  );
};

export const useCampaignContext = () => {
  const context = useContext(CampaignContext);
  if (context === undefined) {
    throw new Error('context needs to be provided in campaign context');
  }
  return context;
};
